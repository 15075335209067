import * as React from "react";
import { Container, Row} from "react-bootstrap";
import Layout from "../components/layout";
import Seo from "../components/seo";
import "../components/css/NewsDetail.scss";
import { Link } from "gatsby";
import { AiFillLinkedin } from 'react-icons/ai';
import { FaTwitter } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { FiArrowLeft } from 'react-icons/fi';


const EdgeqPressKit9 = () => (
  <Layout>
    <Seo title="EdgeQ | WNC and EdgeQ to Introduce" />


    <section className="News-detail">
        <Container>

                <Row>

                  <div className="detail-head">
                   <Link to="/media/#Newss" className="back-btn"><FiArrowLeft/>News Announcements</Link>

                  <h2>WNC and EdgeQ to Introduce World’s First All-in-One, Multi-Mode 4G/5G Small Cell</h2>
                  </div>

                  <div className="detail-para">
                    
              <p className="text-left"><i>WNC’s Newest Small Cell Leverages EdgeQ’s Base Station-on-a Chip to Scale Enterprise Networks at Breakthrough Economics and Performance Throughput</i></p>

              <div className="ajit-social">
                <ul className="social-icons">
                  <li>  
                    <a aria-label="Save" href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFQA4el-DoX9AAAAX7t1dUotlbczx1ZDTlCKPTejfiFEWL5GGZd5oqr6zWvtFKLF3CnnFGCCLco9AXaX4nEgd1nxfFvszEVY7-NA8YXEk9NExe7VTUUStGaSa7qf9cyJahXczA=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fedgeq" target="_blank" rel="noopener noreferrer"><AiFillLinkedin/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://twitter.com/edgeq_inc?lang=en" target="_blank" rel="noopener noreferrer"><FaTwitter/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg" target="_blank" rel="noopener noreferrer"><FaYoutube/></a>
                  </li>
                </ul>
              </div>
                <p>Hsinchu, TAIWAN – Feb 27, 2023 – Wistron NeWeb Corp. (WNC), a leading provider of communications solutions, and EdgeQ Inc, a leader in 5G wireless infrastructure, have partnered to introduce the world’s first software-defined, multi-mode 4G/5G small cell at this year’s Mobile World Congress Barcelona. The collaboration between the two companies enables a new paradigm for telco operators and enterprise customers looking for a singular design that converges 4G and 5G networks, spans indoor and outdoor environments, scales across multiple antennas, and offers field-upgradeable software within a compact unit for frictionless deployment. </p>

                <p>WNC’s newest small cell (LAS1) offers a converged 4G and 5G network that is field upgradable through software with a modular RF antenna design. Supporting Power over Ethernet, the all-in-one small cell integrates compute, connectivity, and cloud functionalities while achieving up to 5 Gbps throughput. Samples of the LAS1 are now available. </p>

                <p>“WNC has implemented the world’s first dual Radio Access Technology (RAT) small cell product based on EdgeQ’s solution,” said Stephen Sek, Chief of Technology, US & EU Technology Centers, WNC. “With our proprietary 2T2R & 4T4R antenna design and an extremely power-efficient EIRP of up to 30 dBm, we are well-positioned to support the next generation of applications requiring over 1 Gbps of user throughput.”</p>

                <p>The partnership between WNC and EdgeQ validates how open innovation at the silicon level drives new performance dimensions at breakthrough economics. The highly scalable, flexibly adaptive EdgeQ platform targets both gNB and O-RAN applications in a completely softwarized manner. By compacting 4G, 5G, and compute into a single-chip, EdgeQ enables an all-in-one small cell with fully integrated Radio Unit (RU), Distributed Unit (DU), and Central Unit (CU); L1, L2, L3 stacks, and fully inclusive ORAN splits. The scalable architecture packs unprecedented throughput performance across a large set of concurrent users, but all within a compact power envelope enabling Power-Over-Ethernet.</p>

                <p>“EdgeQ has imagined a highly flexible, softwarized 5G platform that is cohesive, low power, and remarkably disruptive. Together with WNC, we are introducing to the industry an elegant design that can fulfill the many dimensions of 5G, all the while driving towards new economics and operating simplicity like Wi-Fi,” said Adil Kidwai, Head of Product Management at EdgeQ.</p>


                <p>The WNC LAS1 small cell and EdgeQ’s Base Station-on-a-Chip can be seen at Hall 2 - 2A4MR.</p>
                
                <p>To learn more about WNC’s LAS1 small cell, please visit <Link target="_blank" to="https://www.wnc.com.tw/">https://www.wnc.com.tw</Link></p>

                <p>To learn more about how EdgeQ is pioneering the 5G infrastructure market, please visit <Link to="/">www.edgeq.io</Link>.</p>



            <b>About WNC</b>
            <p>Wistron NeWeb Corporation (WNC) specializes in the design, development, and manufacturing of cutting-edge communications products. WNC’s technical expertise encompasses applications from broadband, multimedia, and the IoT to wireline and wireless communications, with a product scope covering solutions in network communications, network infrastructure, home automation, and advanced driver-assistance systems (ADAS). For more information, please visit: <Link target="_blank" to="https://www.wnc.com.tw/">www.wnc.com.tw</Link></p>
            <br />
            <br />
            <b>About EdgeQ</b>
            <br />
            <br />
            <p>EdgeQ is a leading innovator in 5G systems-on-a-chip. The company is headquartered in Santa Clara, CA, with offices in San Diego, CA and Bangalore, India. Led by executives from Qualcomm, Intel, and Broadcom, EdgeQ is pioneering converged connectivity and AI that is fully software-customizable and programmable. The company is backed by world-renowned investors. To learn more about EdgeQ, visit <Link to="/">www.edgeq.io</Link></p>
            {/* <b>Contacts</b>
            <br />
            <br />
            <a target="_blank" href="https://goo.gl/maps/qwqsKwcTcEVokx1e6">Jordan Tewell, 10Fold Communications</a>
            <br />
            <a href="mailto:edgeq@10fold.com">edgeq@10fold.com</a>
            <br />
            <a href="tel:+4156666066">(415) 666-6066</a> */}
        </div>
                </Row>
        </Container>
    </section>


  </Layout>
);

export default EdgeqPressKit9;